
import { Component, Prop } from 'vue-property-decorator';
import BasicForm from '@/components/auth/venue/BasicForm.vue';
import VWrapper from '@/components/shared/VWrapper.vue';
import Notification from '@/mixins/Notification';
import ProvisionForm from '@/components/auth/venue/ProvisionForm.vue';
import SettingsForm from '@/components/auth/venue/SettingsForm.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { Venue } from '@/interfaces/models/Venue';
import { User } from '@/interfaces/models/User';
import VenueApiService from '@/api/http/VenueApiService';
import InvoiceReceiverForm from '@/components/auth/venue/InvoiceReceiverForm.vue';
import OpeningHourForm from '@/components/venue/form/OpeningHourForm.vue';
import DeliveryHourForm from '@/components/venue/form/DeliveryHourForm.vue';
import uuid from 'uuid/v4';
import CopyBtn from '@/components/auth/venue/CopyBtn.vue';
import { CustomerGroup } from '@/enums/CustomerGroup';
import { Address, HereApiService, Location } from '@/api/http/HereApiService';
import DocumentForm from '@/components/venue/form/DocumentForm.vue';
import PaymentForm from '@/components/auth/venue/PaymentForm.vue';
import { MasterType } from '@/enums/MasterType';
import PrinterForm from '@/components/auth/venue/PrinterForm.vue';
import { Country } from '@/enums/Country';

@Component({
  components: {
    PrinterForm,
    PaymentForm,
    DocumentForm,
    DeliveryHourForm,
    OpeningHourForm,
    InvoiceReceiverForm,
    SettingsForm,
    ProvisionForm,
    BasicForm,
    VWrapper,
    CopyBtn,
  },
})
export default class RegisterVenue extends mixins(StackedForm, Notification) {
  @Prop({ type: String, required: false }) public customerGroup!: CustomerGroup;

  public tabs: string[] = [uuid()];

  public documentVenue: Partial<Venue> | null = null;
  public country: Country | null = null;

  @Prop({
    type: Object,
    default: {},
  })
  public user!: Partial<User>;

  public step: number = 1;

  get printerPrice() {
    if (this.customerGroup === CustomerGroup.SubwayFr || this.customerGroup === CustomerGroup.SubwayLu) {
      return '259';
    }

    return '327';
  }

  get printerPackageInfo() {
    return 'venue.form.orderPrinterTabletPackageInfo';
  }

  get shouldDisableDelivery() {
    return this.customerGroup === CustomerGroup.SubwayFr || this.customerGroup === CustomerGroup.SubwayLu;
  }

  get shouldDisableRegister() {
    return this.customerGroup === CustomerGroup.SubwayFr || this.customerGroup === CustomerGroup.SubwayLu;
  }

  get allowedLegalTypes() {
    if (this.customerGroup === CustomerGroup.SubwayFr || this.customerGroup === CustomerGroup.SubwayLu) {
      return ['sa', 'sarl', 'eurl', 'sas', 'autres'];
    }

    return null;
  }

  get allowedCountries() {
    if (this.customerGroup === CustomerGroup.Subway) {
      return [Country.DE, Country.AU];
    } else if (this.customerGroup === CustomerGroup.SubwayFr) {
      return [Country.FR];
    } else if (this.customerGroup === CustomerGroup.SubwayLu) {
      return [Country.LU];
    }

    return [Country.DE, Country.AU];
  }

  public addTab() {
    this.tabs.push(uuid());
  }

  public removeTab(tab: string) {
    const index: number = this.tabs.indexOf(tab);
    if (index > -1) {
      this.tabs.splice(index, 1);
    }
  }

  public nextStep() {
    this.validateAll().then(async (res: boolean[] | boolean) => {
      if (this.isValid(res)) {
        this.step = this.step + 1;
        const venues: Array<Partial<Venue>> = await this.collectVenues();
        this.documentVenue = venues[0];
      } else {
        this.notifyError('notification.form');
      }
    });
  }

  public async save() {
    this.$refs.document.validate().then(async (res: boolean[] | boolean) => {
      if (this.isValid(res)) {
        this.$startLoading('signup');
        const venues: Array<Partial<Venue>> = await this.collectVenues();
        const api: VenueApiService = new VenueApiService();
        await api.register({
          ...this.user,
          user: this.user._id,
          masterType: MasterType.WEB,
          venues,
          phoneNumber: this.user.phone,
          customerGroup: this.customerGroup,
        });
        this.$router.push({ name: 'register.success' });
        this.$stopLoading('signup');
      } else {
        this.notifyError('notification.form');
      }
    });
  }

  public mounted(): void {
    if (!this.user) {
      // @ts-ignore
      this.$router.go(-1);
    }
  }

  public async validateAll() {
    const refs: any = this.getRefs();
    const results: boolean[] = [];
    for (const ref of refs) {
      results.push(await ref[0].validate());
    }

    return results;
  }

  public async getGeoCoordinates(form: { street: string; number: string; postalCode: string; city: string }) {
    const location: Location | null = await HereApiService.getGeoCoordinates(form as unknown as Address);

    if (location) {
      return {
        type: 'Point',
        coordinates: [location.lng, location.lat],
      };
    }

    return {
      type: 'Point',
      coordinates: [0, 0],
    };
  }

  public getRefs() {
    const refs: any[] = [];
    for (const tab of this.tabs) {
      refs.push(
        ...[
          this.$refs[`basic${tab}`],
          this.$refs[`invoiceReceiver${tab}`],
          this.$refs[`provision${tab}`],
          this.$refs[`settings${tab}`],
          this.$refs[`payment${tab}`],
          this.$refs[`printer${tab}`],
        ],
      );
    }

    return refs;
  }

  public copyValues(innerTab: string, tab: string, type: string) {
    const data: any = this.$refs[`${type}${innerTab}`][0].getData();
    const form: any = this.$refs[`${type}${tab}`][0];
    if (type === 'openingHours') {
      form.setData(data.openingHours);
    } else if (type === 'invoiceReceiver') {
      form.setData(data);
    } else if (type === 'settings') {
      form.setData(data);
    } else if (type === 'document') {
      form.setData({ ...data.legal });
    } else if (type === 'payment') {
      form.setData(data);
    } else if (type === 'printer') {
      const addressData: any = this.$refs[`basic${tab}`][0].getData();
      form.setData({
        ordered: true,
        postalCode: addressData.postalCode,
        city: addressData.city,
        street: addressData.street,
        number: addressData.number,
        name: addressData.name,
      });
    }
  }

  public async changedCountry(country: Country) {
    this.country = country;
  }

  protected async collectVenues(): Promise<Array<Partial<Venue>>> {
    const venues: Array<Partial<Venue>> = [];

    for (const tab of this.tabs) {
      const basic: {
        street: string;
        number: string;
        country: string;
        city: string;
        postalCode: string;
        name: string;
      } = this.$refs[`basic${tab}`][0].getData();
      venues.push({
        ...basic,
        ...this.$refs[`provision${tab}`][0].getData(),
        ...this.$refs[`settings${tab}`][0].getData(),
        ...this.$refs[`openingHours${tab}`][0].getData(),
        ...this.$refs[`payment${tab}`][0].getData(),
        ...this.user,
        masterType: MasterType.WEB,
        city: {
          de: this.$refs[`basic${tab}`][0].getData().city,
        },
        invoiceReceiver: {
          ...this.$refs[`invoiceReceiver${tab}`][0].getData(),
        },
        printerOrder: {
          ...this.$refs[`printer${tab}`][0].getData(),
        },
        legal: this.$refs.document ? this.$refs.document.getData().legal : null,
        location: await this.getGeoCoordinates({ ...basic, city: basic.city }),
        vatNumber: this.$refs[`invoiceReceiver${tab}`][0].getData().vatNumber,
        legalForm: this.$refs[`invoiceReceiver${tab}`][0].getData().legalForm,
        name: basic.name,
        street: basic.street,
        number: basic.number,
        email: this.$refs[`invoiceReceiver${tab}`][0].getData().email,
      });
    }

    return venues;
  }
}
