
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

@Component({})
export default class CopyBtn extends Vue {
  @Prop({ type: String }) public tab!: string;
  @Prop({ type: Array }) public tabs!: string[];
  @Prop({ type: Boolean, default: false }) public includeActive!: boolean;

  @Emit('copy')
  public copyValues(innerTab: string) {
    return innerTab;
  }

  get activeVenues(): string[] {
    if (this.includeActive) {
      return this.tabs;
    }
    return this.tabs.filter((tab: string) => tab !== this.tab);
  }
}
