
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StackedForm from '../../../mixins/StackedForm';
import VFormBuilder from '../../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
@Component({
  components: { VFormBuilder },
})
export default class PrinterForm extends mixins(StackedForm) {
  @Prop({ type: String, default: '327' }) public price!: string;
  @Prop({ type: String, default: 'venue.form.orderPrinterTabletPackageInfo' }) public printerPackageInfo!: string;

  get items() {
    return [
      { label: 'venue.form.orderPremiumPackage', name: 'ordered', type: InputType.Checkbox },
      {
        label: 'venue.form.name',
        name: 'name',
        type: InputType.Text,
        rules: 'required',
        visible: this.visibleCallback,
      },
      {
        label: 'venue.form.street',
        name: 'street',
        type: InputType.Text,
        rules: 'required',
        visible: this.visibleCallback,
      },
      {
        label: 'venue.form.postalCode',
        name: 'postalCode',
        type: InputType.Text,
        rules: 'required',
        visible: this.visibleCallback,
      },
      {
        label: 'venue.form.number',
        name: 'number',
        type: InputType.Text,
        rules: 'required',
        visible: this.visibleCallback,
      },
      {
        label: 'venue.form.city',
        name: 'city',
        type: InputType.Text,
        rules: 'required',
        visible: this.visibleCallback,
      },
    ];
  }

  public visibleCallback(form: { ordered: boolean }) {
    return form.ordered;
  }
}
